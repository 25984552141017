<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <FilterRegional
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.region"/>
                <FilterProvince
                    class="mr-2"
                    v-model:value="state.params.provinsi"
                    v-model:region="state.params.region"/>
                <FilterArea
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.area"
                    v-model:provinsi="state.params.provinsi"/>
                <FilterKabupatenKota
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.kabupaten"
                    v-model:region="state.params.region"
                    v-model:provinsi="state.params.provinsi"
                    v-model:area="state.params.area"/>
            </div>
            <div class="col-md-12 mt-2">
                <AMonthPicker
                    v-has-role="[ROLE_ADMIN_BK, ROLE_TSO, ROLE_ASM, ROLE_DISTRIBUTOR, ROLE_SPC, ROLE_SPC_GROUP]"
                    class="mr-2"
                    v-model:value="state.params.periode"
                    placeholder="Pilih Periode"
                    style="width:300px"
                    format="MMMM YYYY"/>
                <FilterToko
                    class="mr-2"
                    v-model:value="state.params.toko"/>
                <!-- <AInputSearch
                    class="mr-2"
                    v-model:value="state.params.q"
                    placeholder="Cari..."
                    style="width:300px"/> -->
                <AButton
                    class="mr-2"
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </div>
        </div>

        <div class="row justify-content-end mt-4">
          <div class="col-lg-6 col-md-12"></div>
          <div class="col-lg-6 col-md-12 text-right">
            <DownloadExcel
                :url="state.endpoint"
                :params="queryParams()"
                namefile="Laporan-Delete-Suggestion-Toko-Lelang"
                @errors="errorMessage"/>
          </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                row-key="id"
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">
                <template #image="{ record }">
                    <ASpace>
                        <ATooltip
                            v-if="record.image"
                            title="Lihat Gambar">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnImage(record)">
                                <template #icon><FileImageOutlined /></template>
                                Lihat Gambar
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>
            </MdTable>
        </div>

        <!-- detail image -->
        <ImageDetail
            v-if="visibleImageModal"
            v-model:visible="visibleImageModal"
            :item="visibleImageItemModal"/>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { FileImageOutlined } from '@ant-design/icons-vue'
import apiClient from '@/services/axios'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterArea from '@/components/filter/FilterArea'
import FilterToko from '@/components/filter/FilterToko'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import ImageDetail from './ImageDetail'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'
import {
    ROLE_ADMIN_BK,
    ROLE_ASM,
    ROLE_TSO,
    ROLE_DISTRIBUTOR,
    ROLE_SPC,
    ROLE_SPC_GROUP,
    hasRoles,
} from '@/helpers'
import moment from 'moment'

export default defineComponent({
    components: {
        FileImageOutlined,
        FilterProvince,
        FilterKabupatenKota,
        FilterRegional,
        FilterArea,
        FilterToko,
        DownloadExcel,
        ImageDetail,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Periode',
                    dataIndex: 'periode',
                },
                {
                    title: 'Region',
                    dataIndex: 'regional',
                },
                {
                    title: 'ID Toko',
                    dataIndex: 'customer_code',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'customer_name',
                },
                {
                    title: 'Alamat',
                    dataIndex: 'address',
                },
                {
                    title: 'Area',
                    dataIndex: 'area',
                },
                {
                    title: 'Kota',
                    dataIndex: 'city',
                },
                {
                    title: 'Provinsi',
                    dataIndex: 'province',
                },

                {
                    title: 'SSM',
                    dataIndex: 'user_ssm',
                },
                {
                    title: 'ASM',
                    dataIndex: 'user_sm',
                },
                {
                    title: 'TSO',
                    dataIndex: 'user_am',
                },
                {
                    title: 'Catatan',
                    dataIndex: 'note',
                },
                {
                    title: 'Gambar',
                    dataIndex: 'image',
                    slots: { customRender: 'image' },
                },
            ],
            endpoint: '/api/report-lelang/history-delete',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: extractQueryParams({
                q: '',
                region: [],
                provinsi: [],
                periode: moment(new Date()).startOf('month').format('YYYY-MM'),
                area: [],
                kabupaten: [],
                toko: [],
                page: 1,
                "per-page": 10,
            }),
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.periode) {
                params.periode = moment(state.params.periode).format('YYYY-MM')
            }

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get(state.endpoint, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data
                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // handle images
        const visibleImageModal = ref(false);
        const visibleImageItemModal = ref(null);

        const btnImage = (item = null, readOnly = false) => {
            visibleImageModal.value = true
            visibleImageItemModal.value = {
                ...item,
                readOnly,
            }
        }

        // handle vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            state,
            handleTableChange,
            fetchDataList,
            errorMessage,
            queryParams,
            btnImage,
            visibleImageModal,
            visibleImageItemModal,
            ROLE_ADMIN_BK,
            ROLE_ASM,
            ROLE_TSO,
            ROLE_DISTRIBUTOR,
            ROLE_SPC,
            ROLE_SPC_GROUP,
        }
    },
})
</script>
